body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
}

.arrow-content {
    position: relative;
}

.modal-content {
    position: relative;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    z-index: 2;
}

.close-button:hover {
    background-color: rgba(255, 255, 255, 1);
}

.close-icon {
    width: 24px;
    height: 24px;
    color: #333;
}

.left-button,
.right-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    transition: background-color 0.3s ease;
    z-index: 1;
}

.left-button {
    left: 10px;
}

.right-button {
    right: 10px;
}

.left-button:hover,
.right-button:hover {
    background-color: rgba(255, 255, 255, 1);
}

.icon {
    width: 24px;
    height: 24px;
    fill: #333;
}

.image-container {
    width: 80vw;
    height: 80vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image-container img {
    position: relative;
    height: 70vh;
    width: 70vw;
    object-fit: contain;
}

.image-container p {
    position: relative;
    background: #fff;
    padding: 4px 6px;
    margin-top: 1%;
    height: 5%;
    border-radius: 10px;
}

.image_loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.loader {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background: #000;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000, #000) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}