/* Continuous Progress/Processing Bar */
.processing {
    position: relative;
    height: 4px;
    display: block;
    width: 100%;
    background-color: #d9f3ff;
    border-radius: 2px;
    background-clip: padding-box;
    margin: 0.5rem 0 1rem 0;
    overflow: hidden;
}
.processing .continuous {
    background-color: #0095d9;
}
.processing .continuous:before {
    content: "";
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    animation: continuous 1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.processing .continuous:after {
    content: "";
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    animation: continuous-short 3.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 1.15s;
}

@keyframes continuous {
    0% {
        left: -35%;
        right: 100%;
    }
    60% {
        left: 100%;
        right: -90%;
    }
    100% {
        left: 100%;
        right: -90%;
    }
}
@keyframes continuous-short {
    0% {
        left: -200%;
        right: 100%;
    }
    60% {
        left: 107%;
        right: -8%;
    }
    100% {
        left: 107%;
        right: -8%;
    }
}

.uppy-DashboardContent-bar{
    display:none !important;
}

.uppy-Dashboard-Item-progress {
    z-index: 500 !important;
}

.uppy-StatusBar {
    z-index: 500 !important;
}

.is-complete {
    z-index: 500 !important;
}

.fomantic_modal {
    left: auto !important;
    top: auto !important;
    height: auto !important;
}